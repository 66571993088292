import React, { useState, useEffect } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';
import loadable from '@loadable/component';

import { sendInviteLink } from '../../services/voucher.api';
import useHasMounted from '../../hooks/usesHasMounted';
import { StatusType } from '../../types';
import { getUserProfile } from '../../services/userProfile.api';
import styles from './refer.module.scss';

const StatusMessage = loadable(() => import('components/common/StatusMessage'));
const ButtonLoader = loadable(() => import('components/common/ButtonLoader'));

export default () => {
  const hasMounted = useHasMounted();

  const [loading, setLoading] = useState(false);
  const [inviteCode, setInviteCode] = useState('');
  const [status, setStatus] = useState({ message: '', type: StatusType.none });
  const referralLink = `${process.env.SITE_URL}/getstarted?invite_code=${inviteCode}`;

  const schema = yup.object().shape({
    email: yup
      .array()
      .transform(function(value, originalValue) {
        if (this.isType(value) && value !== null) {
          return value;
        }
        return originalValue ? originalValue.split(/[\s,]+/) : [];
      })
      .required('Please enter a valid email address')
      .of(
        yup.string().email(({ value }) => 'Please enter a valid email address'),
      ),
  });

  const getInviteCode = async () => {
    const profile = await getUserProfile();
    setInviteCode(profile.invite_code);
  };

  useEffect(() => {
    getInviteCode();
  }, []);

  const onSubmit = values => {
    try {
      setLoading(true);
      const { email } = values;
      sendInviteLink(email);
      setStatus({
        message:
          'Your friend should receive an email shortly with the invite link.',
        type: StatusType.success,
      });
    } catch (error) {
      setStatus({
        message: 'Something went wrong, please try again.',
        type: StatusType.error,
      });
    }
    setLoading(false);
  };

  const copyToClipboard = e => {
    const textField = document.createElement('textarea');
    textField.innerText = referralLink;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();
  };

  if (!hasMounted) {
    return null;
  }

  const renderEmailInput = () => (
    <Formik
      validationSchema={schema}
      onSubmit={onSubmit}
      initialValues={{
        email: '',
      }}
    >
      {({ handleSubmit, handleChange, values, touched, errors }) => (
        <Row className="mgn-top-30">
          <Col>
            <Form noValidate onSubmit={handleSubmit}>
              <Form.Group controlId="formBasicEmail">
                <Form.Control
                  type="email"
                  placeholder="Enter your friend's email address"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  isInvalid={!!errors.email}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.email}
                </Form.Control.Feedback>
              </Form.Group>
              <div className="text-center cust-modal-footer mgn-top-20">
                <ButtonLoader
                  className="btn-def"
                  type="submit"
                  text="Send"
                  loading={loading}
                />
              </div>
            </Form>
          </Col>
        </Row>
      )}
    </Formik>
  );

  const renderReferralLink = () => (
    <Row>
      <Col>
        <p className={styles.referralLink}>{referralLink}</p>
        <div className="text-center cust-modal-footer mgn-top-20">
          <ButtonLoader
            className="btn-def"
            type="submit"
            text="Copy Link"
            loading={loading}
            onClick={copyToClipboard}
          />
        </div>
      </Col>
    </Row>
  );

  return (
    <>
      <div className="dashboard-wrapper">
        <div className="dash-banner">
          <div className="container-fluid">
            <div className="def-w-max">
              <div className="row">
                <div className="col-sm-6 text-center-xs">
                  <div className="hdr">
                    <h2>My Rewards</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="dash-contents">
          <div className="container-fluid">
            <div className="def-w-max">
              <div className="acc-info-main">
                <div className="row">
                  <div className="col-xs-12">
                    <div className="fmf-card">
                      <div className="fmf-card-hdr clearfix">
                        <div className="float-left">
                          <span>reFUR a friend</span>
                        </div>
                      </div>
                      <div className="fmf-card-body">
                        <p>
                          Refer a friend to give them 30% off on their first
                          order and you'll get $10 off on your next order too.
                        </p>
                        <p>
                          The more you invite the more vouchers you get!
                          <br />
                          You'll receive an email to let you know when you've
                          received Discount Voucher.
                          <br />
                          One voucher applies per order and is automatically
                          applied once your friend has ordered.
                        </p>
                        <p>To refer multiple emails, just insert a comma.</p>
                        {renderEmailInput()}
                        <br />
                        <p>Or, send this link to your friends</p>
                        {renderReferralLink()}
                        <StatusMessage
                          message={status.message}
                          type={status.type}
                        />

                        <div className="mgn-top-30">
                          <p className="font-xs">
                            <br />
                            Note:
                            <br />
                            Vouchers are automatically created when your
                            referred friend orders their first box
                            <br />
                            Vouchers automatically applied to your next order
                            <br />
                            One refer a friend credit redeemable per order
                            <br />
                            Referrals are only valid for new feeding plan
                            customers ordering for the first time
                            <br />
                            Referral must take place using the above email/ link
                            process, prior to ordering
                            <br />
                            Same address referrals will be verified by phone for
                            validation
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="spacer-50" />
    </>
  );
};
